<template>
  <div class="container">
    <div class="flex-column">
      <div
        class="notice bg-light-primary rounded border-primary border border-dashed p-3 text-center text-primary mb-6"
      >
        This section is for special circumstances. It will create a new page after the pricing with any text.
      </div>

      <px-editor
        ref="editor"
        v-if="isLoaded"
        :content="normalizeString(specialNotes)"
        @on-text-change="onSpecialNotesUpdate"
      />

      <!--begin::Actions-->
      <div class="d-flex flex-stack mt-8 pt-8 border-top">
        <!--begin::Wrapper-->
        <div class="me-4">
          <px-btn color="secondary" :in-process="prevInProcess" :disabled="prevInProcess" @click.native="goToPrev">
            Back
          </px-btn>
        </div>
        <!--end::Wrapper-->
        <!--begin::Wrapper-->
        <div class="ms-auto">
          <px-btn
            color="success"
            extended-classes="me-2"
            :in-process="updateInProcess"
            :disabled="updateInProcess"
            @click.native="onUpdate"
          >
            Save
          </px-btn>
          <px-btn :in-process="nextInProcess" :disabled="nextInProcess" @click.native="goToNext">Next </px-btn>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Actions-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

export default {
  mixins: [ProposalWizardMixin],
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      isLoaded: 'proposalWizard/isLoaded',
      specialNotes: 'proposalWizard/specialNotes',
    }),
  },
  methods: {
    ...mapActions({
      megaUpdate: 'proposalWizard/megaUpdate',
    }),
    ...mapMutations({
      setSpecialNotes: 'proposalWizard/SET_SPECIAL_NOTES',
    }),
    onSpecialNotesUpdate() {
      this.setSpecialNotes(JSON.stringify(this.$refs.editor.getContent()));
    },
  },
};
</script>
